import React, {
  ReactNode, useEffect, useMemo, useRef, useState,
} from 'react'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { ROUTES, TITLES } from '../../constants'
import { Form } from '../../components/bulma'
import {
  Label, Panel, PanelBody, Taxonomy,
} from '../../components'
import AppLayout from '../../layouts'
import TextTooltip from '../../components/Tooltip'
import useFetchDatacenter, { useFetchDataServers, useFetchProviders } from '../../hooks/api/datacenter'
import { IPrimaryFields } from '../../types/createDatabase'
import { CreateDatabaseForm, CreateMongoDatabaseForm, CreateOracleDatabaseForm } from '../../modules/createDatabase'
import useEnvironment from '../../hooks/env'

const primarySchema = yup.object({
  dataCenter: yup.string().required('is required').min(1),
  providerName: yup.string().required('is required').min(1),
  serviceOffering: yup.string().required('is required').min(1),
})

function PageLayout({ children, pageName }: {
  children: ReactNode,
  pageName: string
}) {
  return (
    <div className="container is-fluid">
      <h4 className="title is-5">
        <Link to={ROUTES.listDatabases} replace>
          <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
        </Link>
        <FontAwesomeIcon className="pr-3" icon={faDatabase} />
        {pageName}
      </h4>
      {children}
    </div>
  )
}

function CreateDatabase() {
  const env = useEnvironment()
  // Remove isMockMongoDbUi and isMockOracleDbUi variable and its all usage when APi ready to use
  const isMockMongoDbUi = env === 'dev'
  const isMockOracleDbUi = env === 'dev'

  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const [taxonomy, setTaxonomy] = useState<string | undefined>()
  const [isTaxonomyLoading, setIsTaxonomyLoading] = useState(false)

  const editCapability = useMemo(() => {
    const isEdit = pathname === ROUTES.createDatabaseEdit
    if (isEdit) {
      const isValidInitialEditValue = primarySchema.isValidSync(state)
      const canEditCreate = isValidInitialEditValue && isEdit
      return { canEditCreate }
    }
    return { canEditCreate: false }
  }, [pathname, state])

  const { canEditCreate } = editCapability

  const isCreateDatabasePage = useMemo(() => ROUTES.createDatabase === pathname, [pathname])

  const isInitialEditFormReady = useRef(false)

  useEffect(() => {
    if (!canEditCreate) {
      navigate(ROUTES.createDatabase, { replace: true })
    }
  }, [canEditCreate, navigate])

  const initialValues = useMemo(() => {
    if (!canEditCreate) {
      return { dataCenter: '', providerName: '' }
    }
    const tempState = state as Partial<IPrimaryFields>
    return {
      dataCenter: tempState.dataCenter,
      providerName: tempState.providerName,
    }
  }, [state, canEditCreate])

  const {
    watch, formState: { isValid }, setValue, register,
  } = useForm<IPrimaryFields>({
    resolver: yupResolver(primarySchema),
    mode: 'onChange',
    defaultValues: initialValues,
  })
  const values = watch()
  const { data: dataCenterResponse, isFetching: isDatacenterLoading } = useFetchDatacenter()

  const dataCenters = useMemo(() => {
    //  this if block should be removed, when Create MongoDB cluster APi is ready consume
    if (isMockMongoDbUi) {
      const tempArray = ['MongoDB']
      if (dataCenterResponse?.data != null) {
        return [...tempArray, ...dataCenterResponse.data]
      }
      return tempArray
    }

    return dataCenterResponse?.data ?? []
  }, [dataCenterResponse?.data, isMockMongoDbUi])

  const {
    data: providersResponse,
    isFetching: isProviderLoading,
  } = useFetchProviders(values.dataCenter, {
    onSuccess: (response) => {
      if (response.data.length > 0 && (isCreateDatabasePage || isInitialEditFormReady.current)) {
        setValue('providerName', response.data[0], { shouldValidate: true })
      }
    },
  })

  const providers = useMemo(() => {
    //  this if block should be removed, when APi is ready to create of MongoDB
    if ((isMockMongoDbUi || isMockOracleDbUi) && !isProviderLoading) {
      const tempArray = []
      if (isMockMongoDbUi && values.dataCenter === 'MongoDB') {
        tempArray.push('MongoDB')
      }
      if (isMockOracleDbUi && values.dataCenter === 'AWS-RDS') {
        tempArray.push('ORACLE')
      }
      if (providersResponse?.data != null) {
        return [...providersResponse.data, ...tempArray]
      }
      return tempArray
    }
    return providersResponse?.data ?? []
  }, [
    providersResponse?.data,
    isMockMongoDbUi,
    isMockOracleDbUi,
    isProviderLoading,
    values.dataCenter,
  ])

  //  this block should be removed, when APi is ready to create of MongoDB and ORACLE
  useEffect(() => {
    if (isMockMongoDbUi && values.dataCenter === 'MongoDB') {
      setValue('providerName', 'MongoDB')
    }
  }, [isMockMongoDbUi, isMockOracleDbUi, setValue, values.dataCenter])

  const {
    data: serversResponse,
    isFetching: isDataServersLoading,
  } = useFetchDataServers(values.dataCenter, values.providerName, {
    onSuccess: (response) => {
      if (response.data.length > 0 && (isCreateDatabasePage || isInitialEditFormReady.current)) {
        setValue('serviceOffering', response.data[0]._id, { shouldValidate: true })
      }
      if (!isCreateDatabasePage && !isInitialEditFormReady.current) {
        isInitialEditFormReady.current = true
      }
    },
    enabled: !isProviderLoading,
  })
  const servers = serversResponse?.data ?? []

  const isLoading = isTaxonomyLoading
    || isDataServersLoading || isProviderLoading || isDatacenterLoading

  const getCreateDatabaseForm = () => {
    if (values.providerName === 'ORACLE') {
      return <CreateOracleDatabaseForm parentProps={values} />
    } if (values.providerName === 'MongoDB') {
      return <CreateMongoDatabaseForm />
    }
    return (
      <CreateDatabaseForm
        isPrimaryFormValid={isValid}
        taxonomyId={taxonomy}
        {...values}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title>{TITLES.createDatabase}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <PageLayout pageName="On Demand Databases & Clusters > Create">
          <Panel title="Create Database">
            <PanelBody>
              <form onSubmit={(e) => { e.preventDefault() }}>
                <Form.Field>
                  <Label
                    label="Datacenter"
                    for="dataCenter"
                    required
                    popover={(
                      <TextTooltip>
                        A connectivity request to the database VIP can be made
                        from anywhere within the Disney Global Network.
                      </TextTooltip>
                    )}
                  />
                  <Form.Control>
                    <div className="select is-fullwidth">
                      <select id="dataCenter" {...register('dataCenter')}>
                        <option value="" disabled>Select a Data Center</option>
                        {dataCenters.map((dataCenter, i) => <option key={`${i + dataCenter}`} value={dataCenter}>{dataCenter}</option>)}
                      </select>
                    </div>
                  </Form.Control>
                </Form.Field>
                <Form.Field>
                  <Label label="Provider" required />
                  <Form.Control>
                    <div className="select is-fullwidth">
                      <select disabled={providers.length === 0} id="providerName" {...register('providerName')}>
                        {providers.map((provider) => (
                          <option
                            key={provider}
                            value={provider}
                          >
                            {provider}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Form.Control>
                </Form.Field>
                <Form.Field>
                  <Label label="Service offering" required />
                  <Form.Control>
                    <div className="select is-fullwidth">
                      <select disabled={servers.length === 0} id="serviceOffering" {...register('serviceOffering')}>
                        {servers.map((server) => (
                          <option
                            key={server._id}
                            value={server._id}
                          >
                            {server.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Form.Control>
                </Form.Field>
                <Taxonomy onLoading={setIsTaxonomyLoading} onSelected={setTaxonomy} />

                {getCreateDatabaseForm()}
              </form>
            </PanelBody>
          </Panel>
        </PageLayout>
      </AppLayout>
    </>
  )
}

export default CreateDatabase
