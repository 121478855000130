import React from 'react'
import { SideNav, SideNavItem } from '@bespin-ui/react-ui-components'
import {
  BiConversation, BiCloud, BiServer, BiSpreadsheet, BiUserPlus,
} from 'react-icons/bi'
import { AppSpinner } from '../components'
import { Columns } from '../components/bulma'
import ROUTES from '../constants/ROUTES'
import { useAuth } from '../providers/AuthProvider'
import TopNav from '../components/TopNav'
import AdminContainer from './AppLayoutStyles'
import useEnvironment from '../hooks/env'

function AppLayout({ children, isLoading }: { children: React.ReactNode, isLoading?: boolean }) {
  const { isAdmin, isAuthenticated } = useAuth()
  const loading = (isLoading ?? false)
  const env = useEnvironment()

  return (
    <AppSpinner isLoading={loading}>
      {isAuthenticated
          && (
            <AdminContainer>
              <TopNav />
              <Columns multiline={false} display="flex" flexWrap="nowrap" justifyContent="center" className="pr-4">
                {isAdmin
                  && (
                    <Columns.Column size={2}>
                      <SideNav data-testid="sidebar">
                        <SideNavItem
                          icon={<BiCloud />}
                          label="Servers"
                          href={ROUTES.listServers}
                        />
                        <SideNavItem
                          icon={<BiServer />}
                          label={env === 'dev' ? 'Databases & Clusters' : 'Databases'}
                          href={ROUTES.listDatabases}
                        />
                        <SideNavItem
                          icon={<BiUserPlus />}
                          label="Administrator"
                          href={ROUTES.listAdministrator}
                        />
                        <SideNavItem
                          icon={<BiConversation />}
                          label="Events"
                          href={ROUTES.listEvents}
                        />
                        <SideNavItem
                          icon={<BiSpreadsheet />}
                          label="Logs"
                          href={ROUTES.listLogs}
                        />
                      </SideNav>
                    </Columns.Column>
                  )}
                <Columns.Column size={10}>{children}</Columns.Column>
              </Columns>
            </AdminContainer>
          )}
    </AppSpinner>
  )
}

AppLayout.defaultProps = {
  isLoading: false,
}

export default AppLayout
