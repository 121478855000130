import classNames from 'classnames'
import React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button, Form } from '../../../components/bulma'
import { Label, Panel, PanelBody } from '../../../components'
import { IOracleFormFields, IPrimaryFields } from '../../../types/createDatabase'

const schema = yup.object({
  capacity: yup.number().typeError('Must be an integer.').positive().required('is required')
    .min(1)
    .max(10, 'Must be no more than 10 GBs.'),
  databaseName: yup.string().required('is required')
    .matches(/^[a-zA-Z0-9_]+$/, { message: 'Must only contain alphanumeric characters. Underscore is also allowed' })
    .min(1)
    .max(64, 'Must be shorter than 64 characters'),
  backupRetentionPeriod: yup.number().typeError('Must be an integer.').positive().required('is required')
    .min(7)
    .max(35, 'Must be no more than 35 Days.'),
  backupWindow: yup.string().required(),
  allowMajorVersionUpgrade: yup.boolean().required('is required'), // false
  autoMinorVersionUpgrade: yup.boolean().required('is required'), // true,
  customerAdminUsername: yup.string().required('is required')
    .matches(/^[a-zA-Z][0-9a-zA-Z_]*$/, { message: 'Must begin with alpha and only contain alphanumeric characters. Underscore is also allowed' })
    .min(4, 'Must be longer than 3 chars.')
    .max(128, 'Must be shorter than 129 characters.'),
  customerAdminPassword: yup.string()
    .required('is required')
    .notOneOf([yup.ref('user')], 'cannot be username')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@&^#$!`*'\-%])[A-Za-z\d\\@&^#$!`*'\-%]{10,128}$/,
      { message: "Password should meet specified requirements. It should satisfy all of these combinations: length 10-128, [a-z], [A-Z], numbers, @, &, ^, #, $, !, `, *, ', -, %,;, cannot be username" },
    ),
  license: yup.string().required(),
  timezone: yup.string().required(),
  identifier: yup.string().required(),
  multiAz: yup.boolean(),
  maintenanceWindow: yup.string()
    .required(),
  engineVersion: yup.string(),
})

interface IProps {
  parentProps?: Partial<IPrimaryFields>
}

function CreateOracleDatabaseForm({ parentProps }:IProps) {
  const navigation = useNavigate()

  const {
    formState: { errors }, register, watch, setValue,
  } = useForm<IOracleFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      backupWindow: '22:00-23:59',
      backupRetentionPeriod: 7,
      autoMinorVersionUpgrade: true,
      allowMajorVersionUpgrade: false,
      multiAz: false,
      license: 'license-included',
      timezone: 'UTC',
      maintenanceWindow: 'Tue:22:00-Wed:02:00',
      engineVersion: '19c-xxxxPSU',
      identifier: 'Production',
    },
  })

  const values = watch()

  return (
    <>
      <Form.Field>
        <Label label="Database Name" required for="databaseName" />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.databaseName != null })} {...register('databaseName')} id="databaseName" />
        </Form.Control>
        {errors.databaseName && <Form.Help color="danger">{errors.databaseName.message}</Form.Help>}
      </Form.Field>

      <Form.Field>
        <Label label="Size" required />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.capacity != null })} {...register('capacity')} id="capacity" />
        </Form.Control>
        <Form.Help color="danger">{errors.capacity != null ? errors.capacity.message : ''}</Form.Help>
      </Form.Field>

      <Form.Field horizontal mt={5} alignItems="baseline">
        <Form.Field.Label textAlign="left" className="mr-0 title is-6">Engine Version</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Radio
                {...register('engineVersion')}
                value="19c-xxxxPSU"
              >
                19c-xxxxPSU
              </Form.Radio>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Panel className="mt-5" titleClassName="" title="Identifier">
        <PanelBody>
          <Form.Control className="mb-2">
            <Form.Radio {...register('identifier')} value="Production" checked={values.identifier === 'Production'}>Production</Form.Radio>
          </Form.Control>
          <Form.Control>
            <Form.Radio {...register('identifier')} value="Non-Production" checked={values.identifier === 'Non-Production'}>Non-Production</Form.Radio>
          </Form.Control>
        </PanelBody>
      </Panel>
      <Form.Field>
        <Label label="Timezone" />
        <Form.Control>
          <Form.Select
            id="timezone"
            {...register('timezone')}
            value={values.timezone ?? ''}
            fullwidth
          >
            <option value="UTC">UTC</option>
            <option value="PST">PST</option>
            <option value="EST">EST</option>
            <option value="any">ANY</option>
          </Form.Select>
        </Form.Control>
      </Form.Field>
      <Form.Field>
        <Label label="License Model" />
        <Form.Control>
          <Form.Select
            id="license"
            fullwidth
            {...register('license')}
            value={values.license ?? ''}
          >
            <option value="license-included">License Included</option>
            <option value="BYOL">BYOL (Bring your Own License)</option>
          </Form.Select>
        </Form.Control>
      </Form.Field>

      <Form.Field>
        <Label label="Backup Window" for="backupWindow" />
        <Form.Control>
          <Form.Select
            id="backupWindow"
            fullwidth
            {...register('backupWindow')}
            value={values.backupWindow ?? ''}
          >
            <option value="12:00-13:59">12:00 - 13:59</option>
            <option value="14:00-15:59">14:00 - 15:59</option>
            <option value="16:00-17:59">16:00 - 17:59</option>
            <option value="18:00-19:59">18:00 - 19:59</option>
            <option value="20:00-21:59">20:00 - 21:59</option>
            <option value="22:00-23:59">22:00 - 23:59</option>
            <option value="00:00-01:59">00:00 - 01:59</option>
            <option value="02:00-03:59">02:00 - 03:59</option>
            <option value="04:00-05:59">04:00 - 05:59</option>
            <option value="06:00-07:59">06:00 - 07:59</option>
            <option value="08:00-09:59">08:00 - 09:59</option>
            <option value="10:00-11:59">10:00 - 11:59</option>
          </Form.Select>
        </Form.Control>
      </Form.Field>

      <Form.Field>
        <Label label="Backup Retention Period" />
        <Form.Control>
          <input
            className={classNames('input', { 'is-danger': errors.backupRetentionPeriod != null })}
            {...register('backupRetentionPeriod')}
            id="backupRetentionPeriod"
          />
        </Form.Control>
      </Form.Field>

      <Form.Field>
        <Label label="Maintenance Window" for="maintenanceWindow" />
        <Form.Control>
          <Form.Input id="maintenanceWindow" {...register('maintenanceWindow')} value={values.maintenanceWindow ?? ''} disabled />
        </Form.Control>
      </Form.Field>

      <Form.Field horizontal mt={5} alignItems="baseline">
        <Form.Field.Label textAlign="left" className="mr-0 title is-6">Allow Major Version Upgrade</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Checkbox
                {...register('allowMajorVersionUpgrade')}
                onChange={(e) => {
                  setValue('allowMajorVersionUpgrade', e.target.checked)
                }}
                checked={values.allowMajorVersionUpgrade ?? false}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal mt={5} alignItems="baseline">
        <Form.Field.Label textAlign="left" className="mr-0 title is-6">Auto Minor Version Upgrade</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Checkbox
                {...register('autoMinorVersionUpgrade')}
                onChange={(e) => {
                  setValue('autoMinorVersionUpgrade', e.target.checked)
                }}
                checked={values.autoMinorVersionUpgrade ?? false}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal mt={5} alignItems="baseline">
        <Form.Field.Label textAlign="left" className="mr-0 title is-6">Multi-AZ</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Checkbox
                {...register('multiAz')}
                onChange={(e) => {
                  setValue('multiAz', e.target.checked)
                }}
                checked={values.multiAz ?? false}
              >
                {values.multiAz ? 'Active' : 'Inactive'}
              </Form.Checkbox>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field>
        <Label label="Customer admin username" required for="customerAdminUsername" />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.customerAdminUsername != null })} {...register('customerAdminUsername')} id="customerAdminUsername" />
        </Form.Control>
        <Form.Help color="danger">{errors.customerAdminUsername ? errors.customerAdminUsername.message : ''}</Form.Help>
      </Form.Field>

      <Form.Field>
        <Label label="Customer admin password" required for="customerAdminPassword" />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.customerAdminPassword != null })} {...register('customerAdminPassword')} id="customerAdminPassword" />
        </Form.Control>
        <Form.Help color="danger">{errors.customerAdminPassword ? errors.customerAdminPassword.message : ''}</Form.Help>
      </Form.Field>

      <Form.Field className="is-flex is-justify-content-space-between mt-5">
        <Button color="danger" onClick={() => { navigation(-1) }}>Cancel</Button>
        <Button
          disabled={false}
          color="success"
          type="submit"
          onClick={() => {
            // eslint-disable-next-line no-console
            console.log({
              ...parentProps,
              ...values,
              // identifier: 'Production',
              maintenanceWindow: 'Tue:22:00-Wed:02:00',
            })
          }}
        >
          Submit
        </Button>
      </Form.Field>
    </>
  )
}

CreateOracleDatabaseForm.defaultProps = {
  parentProps: undefined,
}

export default CreateOracleDatabaseForm
