import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import moment from 'moment'
import { BiPlus } from 'react-icons/bi'
import { Button, Icon } from '@bespin-ui/react-ui-components'
import { ColumnDef } from '@tanstack/react-table'
import {
  Button as BulmaButton, Columns, Container, Form,
} from '../../components/bulma'
import {
  ActionButtons,
  ConfirmModal, ErrorBannerNotification, Panel, PanelBody, Table,
} from '../../components'
import AppLayout from '../../layouts'
import { ROUTES, TITLES } from '../../constants'
import { useDeleteAdministratorById, useFetchAdmins } from '../../hooks/api/admin'
import IAdministrator from '../../types/admin'
import useMaintenanceStatus from '../../hooks/maintenance'

import useEnvironment from '../../hooks/env'

function ListAdministrator() {
  const env = useEnvironment()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const [splashFormEnabled, setSplashFormEnabled] = useState(false)
  const [customSplashText, setCustomSplashText] = useState('')

  const { data: adminsResponse, isFetching: isAdminsLoading } = useFetchAdmins()
  const admins = adminsResponse?.data ?? []

  const [selectedAdministrator, setSelectedAdministrator] = useState<IAdministrator | undefined>()

  const onClickCreateServerPage = () => {
    navigate(ROUTES.createAdministrator)
  }

  const {
    mutate, isLoading: isAdminDeleteLoading, isError, error, reset,
  } = useDeleteAdministratorById()

  const isLoading = isAdminsLoading || isAdminDeleteLoading

  const { setMaintenanceStatus } = useMaintenanceStatus()

  const columns: ColumnDef<IAdministrator>[] = React.useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'name',
      id: 'name',
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => ActionButtons(
        {
          ...{
            editCallback: () => { navigate(`${pathname}/${row.original._id}/edit`) },
            deleteCallback: () => { setSelectedAdministrator(row.original) },
          },
        },
      ),
      meta: {
        width: '140px',
      },
    },
  ], [navigate, pathname])

  return (
    <>
      <Helmet>
        <title>{TITLES.listAdministrators}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <ConfirmModal
          title={`Are you sure you want to delete ${selectedAdministrator?.name}`}
          description={`You are about to delete the Admin ${selectedAdministrator?.name}, you can not undo this action, are you sure you want to continue?`}
          isOpen={selectedAdministrator != null}
          okayButton={(
            <BulmaButton
              color="success"
              onClick={() => {
                if (selectedAdministrator != null) {
                  mutate(selectedAdministrator?._id ?? '', {
                    onSettled: () => { setSelectedAdministrator(undefined) },
                    onSuccess: () => {
                      bulmaToast.toast({
                        message: `<b>${selectedAdministrator.name}</b> user is successfully deleted`,
                        type: 'is-success',
                        dismissible: true,
                        duration: 6000,
                        position: 'bottom-right',
                        animate: { in: 'fadeIn', out: 'fadeOut' },
                      })
                    },
                  })
                } else {
                  setSelectedAdministrator(undefined)
                }
              }}
              disabled={isAdminDeleteLoading}
            >
              Delete
            </BulmaButton>
          )}
          cancelButton={(
            <BulmaButton
              disabled={isAdminDeleteLoading}
              onClick={() => { setSelectedAdministrator(undefined) }}
            >
              Cancel
            </BulmaButton>
          )}
        />
        <Container className="is-fluid pr-0 pl-0">
          <Columns multiline={false}>
            <Columns.Column>
              <h4 className="title is-4">
                <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                Administrators
              </h4>
            </Columns.Column>
          </Columns>
          {env === 'unknown'
          && (
          <Columns multiline>
            <Columns.Column size={12}>
              <Panel title="Admin Settings">
                <PanelBody>
                  <Columns>
                    <Columns.Column className="is-flex is-flex-direction-row-reverse">
                      <Form.Field>
                        <Form.Switch
                          name="Enable splash page"
                          checked={splashFormEnabled}
                          onChange={() => { setSplashFormEnabled(!splashFormEnabled) }}
                        />
                      </Form.Field>
                    </Columns.Column>
                  </Columns>
                  {splashFormEnabled
                    && (
                      <>
                        <Form.Field horizontal pt={3}>
                          <Form.Field.Label size="normal">
                            <Form.Label>
                              Custom Splash Page Message*
                            </Form.Label>
                          </Form.Field.Label>
                          <Form.Field.Body>
                            <Form.Field>
                              <Form.Control fullwidth>
                                <Form.TextArea value={customSplashText} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setCustomSplashText(e.target.value)} placeholder="DBaaS is under maintenance..." />
                              </Form.Control>
                            </Form.Field>
                          </Form.Field.Body>
                        </Form.Field>
                        <Form.Field display="flex" justifyContent="flex-end">
                          <Form.Control>
                            <BulmaButton
                              color="success"
                              disabled={!customSplashText.length}
                              type="button"
                              onClick={() => {
                                localStorage.setItem('isByPassEnabled', 'false')
                                localStorage.setItem('maintenance', JSON.stringify({ message: customSplashText, date: moment().add(1, 'h').toDate().toString() }))
                                setMaintenanceStatus({ isLoading: false, isUnderMaintenance: true })
                                setCustomSplashText('')
                                setSplashFormEnabled(false)
                              }}
                            >
                              Save
                            </BulmaButton>
                          </Form.Control>
                        </Form.Field>
                      </>
                    )}
                </PanelBody>
              </Panel>
            </Columns.Column>
          </Columns>
          )}
          <Columns multiline={false}>
            <Columns.Column className="is-flex is-flex-direction-row-reverse">
              <Button
                onClick={onClickCreateServerPage}
                size="small"
                variant="primary"
                label="Create New Administrator"
                icon={<Icon icon={<BiPlus />} />}
              />
            </Columns.Column>
          </Columns>
          {isError
            && (
              <Columns>
                <Columns.Column size={12}>
                  <ErrorBannerNotification
                    message={error?.message ?? ''}
                    dismissButtonCallback={reset}
                  />
                </Columns.Column>
              </Columns>
            )}
          <Columns>
            <Columns.Column>
              <Table
                columns={columns}
                data={admins}
              />
            </Columns.Column>
          </Columns>
        </Container>
      </AppLayout>
    </>
  )
}

export default ListAdministrator
