import { useMutation, useQueryClient } from 'react-query'
import { useDBaaSClient } from '../../client'
import IDatabase from '../../../types/databases/index'

interface ICreateDatabaseBody {
    databaseName: string,
    adminUsername: string,
    serverId: string,
    taxonomyId: string,
    size: number,
    password: string,
    owners: string[]
}

interface IDatabaseResponse {
  output: Partial<IDatabase>
}

const useCreateDatabase = () => {
  const { post } = useDBaaSClient()

  const queryClient = useQueryClient()

  return useMutation((body: ICreateDatabaseBody) => post<IDatabaseResponse>('/databases', body), {
    onSuccess: () => {
      queryClient.invalidateQueries('databases')
    },
  })
}

export default useCreateDatabase
