import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import {
  DetailsTable, ErrorBannerNotification, NodesTable, Panel,
  PanelBody,
  Spinner,
} from '../../components'
import { ROUTES, TITLES } from '../../constants'
import { useFetchDatabaseById, useFetchDatabaseStats } from '../../hooks/api/databases'
import { useFetchTaxonomyDetailsById } from '../../hooks/api/taxonomy'
import { Columns, Container, Form } from '../../components/bulma'
import useEnvironment from '../../hooks/env'

const HighchartsReactContainer = styled.div`
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 14px 14px;
    background: white;
    border-radius: 3px;
    border: 1px solid #eeeeee;
`

interface INavigationParams {
  databaseID: string
}

function ViewDatabaseDetails() {
  const { databaseID } = useParams<keyof INavigationParams>()

  const env = useEnvironment()
  const [isMongoDbMockUiData, setIsMongoDbMockUiData] = useState(false)

  const {
    data: databaseResponse,
    isFetching: isDatabaseLoading, isError: isDatabaseError,
  } = useFetchDatabaseById(databaseID)

  const database = databaseResponse?.data

  const {
    data: taxonomyDetailsResponse,
    isFetching: isTaxonomyLoading, isError: isTaxonomyError,
  } = useFetchTaxonomyDetailsById(database?.taxonomyId, { enabled: database != null })

  const taxonomyData = taxonomyDetailsResponse?.data

  const {
    data: databaseStatsResponse,
    isFetching: isDatabaseStatsLoading, isError: isDatabaseStatsError,
  } = useFetchDatabaseStats(database?._id, { enabled: database != null })

  const databaseStats = databaseStatsResponse?.data

  const isLoading = isDatabaseLoading || isTaxonomyLoading || isDatabaseStatsLoading

  const seriesData = useMemo(() => {
    const data: Array<(string | number)[]> = []
    databaseStats?.forEach((state) => {
      data.push([state.timestamp, Number(state.total_size_mb)])
    })
    return data
  }, [databaseStats])

  const data = useMemo(() => {
    const details = [
      { name: 'ID', value: database?._id ?? '' },
      { name: 'Provider Name', value: database?.providerName ?? '' },
      { name: 'Database Name', value: database?.databaseName ?? '' },
      { name: 'Size', value: (database?.size != null) ? database.size.toString() : '' },
      { name: 'Connection String', value: database?.connectionString ?? '' },
      { name: 'Created By', value: database?.createdBy ?? '' },
      { name: 'Owners', value: database?.owners.join(' ') ?? '' },
      { name: 'Server ID', value: database?.serverId ?? '' },
      { name: 'Taxonomy ID', value: database?.taxonomyId ?? '' },
      { name: 'Segment', value: taxonomyData?.businessUnitGroupName ?? '' },
      { name: 'Business Unit', value: taxonomyData?.businessName ?? '' },
      { name: 'Affiliate Name', value: taxonomyData?.affiliateName ?? '' },
      { name: 'Admin Username', value: database?.adminUsername.toUpperCase() ?? '' },
      { name: 'Created At', value: database?.createdAt ?? '' },
      { name: 'Updated At', value: database?.updatedAt ?? '' },
    ]
    return details
  }, [database, taxonomyData])

  const mongoDbMockUiData = [
    { name: 'Organization', value: 'Disneyverse' },
    { name: 'Project', value: 'Stream Disney' },
    { name: 'Instance Size', value: 'M10' },
    { name: 'Ram', value: '8 GB' },
    { name: 'vCPU', value: '2' },
    { name: 'Disk Size', value: '20 GB' },
    { name: 'Cluster Region', value: 'us-west-2' },
    { name: 'Cluster Name', value: 'Cluster0' },
    { name: 'Electable Nodes', value: '3' },
    { name: 'ReadOnly Nodes', value: '3' },
    { name: 'MongoDB Version', value: '5' },
    { name: 'Backup', value: 'Active' },
    { name: 'Private Endpoint', value: 'Active' },
  ]

  const options: Highcharts.Options = useMemo(() => ({
    title: {
      text: 'Usage History',
      align: 'left',
    },
    chart: {
      renderTo: 'container',
      zoomType: 'xy',
      reflow: true,
      type: 'line',
      events: {},
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        type: 'datetime',
        formatter: (obj: Highcharts.AxisLabelsFormatterContextObject) => {
          const d = new Date(seriesData[Number(obj.value)][0])
          return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`
        },
      },
    },
    yAxis: {
      labels: {
        format: '{value:.0f}',
      },
      title: {
        text: 'MB',
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
    },
    accessibility: { enabled: false },
    series: [{
      name: 'Storage Usage',
      data: seriesData,
      type: 'line',
    }],
  }), [seriesData])

  const getErrorNotification = useMemo(() => {
    if (isDatabaseError) {
      return (
        <ErrorBannerNotification
          message="Failed to fetch Database information. Please contact DBaaS support."
        />
      )
    }
    if (isDatabaseStatsError) {
      return (
        <ErrorBannerNotification
          message="Failed to fetch Database stats. Please contact DBaaS support."
        />
      )
    }
    if (isTaxonomyError) {
      return (
        <ErrorBannerNotification
          message="Failed to fetch Taxonomy details. Please contact DBaaS support."
        />
      )
    }
    return ''
  }, [isDatabaseError, isDatabaseStatsError, isTaxonomyError])

  const databaseOrClusterNameText = useMemo(() => {
    if (isMongoDbMockUiData) {
      return 'Cluster0 : '
    }
    return database?.databaseName != null ? `${database?.databaseName} : ` : ''
  }, [database?.databaseName, isMongoDbMockUiData])

  return (
    <>
      <Helmet>
        <title>{TITLES.viewDatabaseDetails}</title>
      </Helmet>
      <Spinner isLoading={isLoading} isFullScreen>
        <Container>
          <Columns multiline className="pt-5">
            <Columns.Column size={12}>
              <h4 className="title is-4">
                <Link to={ROUTES.listDatabases} replace>
                  <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
                </Link>
                <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                Databases &gt; View
                {' '}
                {databaseOrClusterNameText}
              </h4>
              {getErrorNotification}
            </Columns.Column>
            {env === 'dev'
              && (
                <Columns.Column display="flex" flexDirection="row-reverse">
                  <Form.Checkbox
                    checked={isMongoDbMockUiData}
                    onChange={(e) => { setIsMongoDbMockUiData(e.target.checked) }}
                  >
                    Mock MongoDB UI
                  </Form.Checkbox>
                </Columns.Column>
              )}
          </Columns>
          <Columns flexDirection="column" multiline={false}>
            <Columns.Column>
              <DetailsTable data={isMongoDbMockUiData ? mongoDbMockUiData : data} />
            </Columns.Column>
            {isMongoDbMockUiData && (
              <Columns.Column>
                <Panel title="Nodes" titleContainerClassName="px-2 py-1" titleClassName="" className="p-0">
                  <PanelBody>
                    <Panel title="Region us-west-2" titleContainerClassName="px-2 py-1" titleClassName="">
                      <NodesTable data={[{
                        status: 'Active',
                        value: 'cluster0-shard-00-00.twnx.mongodb.net:27017',
                        type: 'Primary',
                      }, {
                        status: 'Active',
                        value: 'cluster0-shard-00-02.twnx.mongodb.net:27017',
                        type: 'Secondary',
                      },
                      {
                        status: 'Active',
                        value: 'cluster0-shard-00-03.twnx.mongodb.net:27017',
                        type: 'Secondary',
                      }]}
                      />
                    </Panel>
                  </PanelBody>
                </Panel>
              </Columns.Column>
            )}

            <Columns.Column>
              {!isMongoDbMockUiData
                && (
                  <HighchartsReactContainer>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  </HighchartsReactContainer>
                )}
            </Columns.Column>
          </Columns>
        </Container>
      </Spinner>
    </>
  )
}

export default ViewDatabaseDetails
