import {
  faCaretLeft, faDatabase, faEye, faPlus,
} from '@fortawesome/free-solid-svg-icons'
import { BiPencil, BiBlock } from 'react-icons/bi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { Button, Icon, ButtonGroup } from '@bespin-ui/react-ui-components'
import styled from 'styled-components'
import {
  Button as BulmaButton, Container, Form,
} from '../../components/bulma'
import {
  AlertModal, ErrorBannerNotification, Panel, PanelBody,
} from '../../components'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import { ROUTES, TITLES } from '../../constants'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import { useCreateDatabase } from '../../hooks/api/databases'
import AppLayout from '../../layouts'
import { useAuth } from '../../providers/AuthProvider'
import ICreateDataBaseFormData from '../../types/forms/database'
import WithKeyOptional from '../../types/utils'

const ActionButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  button {
    margin-left: auto;
  } 
`

interface BaseForm extends WithKeyOptional<ICreateDataBaseFormData> {
  taxonomyId: string | undefined
}

function CreateDatabaseConfirm() {
  const { isAuthenticated } = useAuth()
  useFetchIsAdmin({ enabled: !isAuthenticated })

  const [isPasswordHidden, setIsPasswordHidden] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()
  const formState = useMemo(() => location.state as BaseForm | undefined, [location])

  const password = useMemo(() => {
    if ((formState?.password) != null && (Boolean(formState.password.length))) {
      if (isPasswordHidden) {
        let hiddenPass = ''
        for (let index = 0; index < formState.password.length; index++) {
          hiddenPass = `${hiddenPass}*`
        }
        return hiddenPass
      }
      return formState.password
    }
    return undefined
  }, [formState?.password, isPasswordHidden])

  const isValidState = useMemo(() => {
    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@&^#$!`*';\-%])[A-Za-z\d\\@&^#$!`*';\-%]{10,128}$/
    if (
      ((formState?.capacity) != null) && !Number.isNaN(formState?.capacity)
      && Number(formState?.capacity) > 0
      && ((formState?.taxonomyId) != null && formState?.taxonomyId.length > 0)
      && ((formState?.dataCenter) != null && ((formState?.dataCenter?.length) !== 0))
      && ((formState?.providerName) != null && ['MSSQL', 'MYSQL', 'PostgreSQL'].some((provider) => formState.providerName === provider))
      && ((formState?.password) != null && passwordPattern.test(formState.password))
      && ((formState?.serviceOffering) != null && formState.serviceOffering.length > 10)
    ) {
      return true
    }
    return false
  }, [formState])

  const {
    mutate, isError, isLoading, data: mutationResponse,
  } = useCreateDatabase()

  const response = mutationResponse?.data

  useEffect(() => {
    if (isError || !isValidState) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [isError, isLoading, isValidState])

  const onClickCreateDatabase = () => {
    mutate({
      adminUsername: formState?.user as string,
      databaseName: formState?.databaseName as string,
      owners: formState?.owners as string[],
      password: formState?.password as string,
      serverId: formState?.serviceOffering as string,
      size: Number(formState?.capacity) as number,
      taxonomyId: formState?.taxonomyId as string,
    }, {
      onError: () => {
        setIsModalOpen(false)
      },
      onSuccess: () => {
        setIsModalOpen(false)
        bulmaToast.toast({
          message: `<b>${formState?.databaseName}</b> Database is successfully created`,
          type: 'is-success',
          dismissible: true,
          duration: 6000,
          position: 'bottom-right',
          animate: { in: 'fadeIn', out: 'fadeOut' },
        })
        setShowSuccessModal(true)
      },
    })
  }

  return (
    <>
      <Helmet>
        <title>{TITLES.createDatabaseConfirm}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <ConfirmModal
          isReverseActionButtonsPosition
          isOpen={isModalOpen}
          title="Are you sure you want to create ?"
          description="You are about to create the database , you can not undo this action, are you sure you want to continue?"
          okayButton={(
            <BulmaButton disabled={isLoading} onClick={onClickCreateDatabase} color="success">
              {' '}
              <FontAwesomeIcon icon={faPlus} className="mr-1" />
              {' '}
              Create Database
            </BulmaButton>
          )}
          cancelButton={
            <BulmaButton data-testid="modalCancelButton" disabled={isLoading} color="default" onClick={() => { setIsModalOpen(false) }}>Cancel</BulmaButton>
          }
        />
        <AlertModal
          isOpen={showSuccessModal}
          title={`'${response?.output.databaseName}' database has been created successfully`}
          content={(
            <ul>
              <li>
                <strong>Database Name:</strong>
                {' '}
                {response?.output.databaseName}
              </li>
              <li>
                <strong>Admin User:</strong>
                {' '}
                {response?.output.adminUsername}
              </li>
              <li>
                <strong>Connection String:</strong>
                {' '}
                {response?.output.connectionString}
              </li>
            </ul>
          )}
          okayButton={(
            <BulmaButton
              onClick={() => {
                navigate(ROUTES.listDatabases, {
                  replace: true,
                })
              }}
              color="success"
            >
              Okay
            </BulmaButton>
          )}
        />
        <Container className="is-fluid">
          <h4 className="title is-4">
            <Link to={ROUTES.createDatabase} replace>
              <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
            </Link>
            <FontAwesomeIcon className="pr-3" icon={faDatabase} />
            <span>On Demand Databases & Clusters &gt; Create &gt; Summary</span>
          </h4>
          {showErrorModal && (
            <ErrorBannerNotification
              dismissButtonCallback={isError ? () => { setShowErrorModal(false) } : undefined}
              message={isError
                ? `Failed to create database '${formState?.databaseName}'. Please contact DBaaS support.`
                : 'Unexpected error occurred!. Check your inputs and try again or contact DBaaS support.'}
            />
          )}
          <Panel title="Summary">
            <PanelBody>
              <p>
                Datacenter:
                {formState?.dataCenter}
              </p>
              <p>
                Provider:
                {formState?.providerName}
              </p>
              <p>
                Business Unit:
                {formState?.taxonomyId}
              </p>
              <p>
                Size:
                {formState?.capacity}
              </p>
              <p>
                Admin Username:
                {formState?.user}
              </p>
              <p>
                Additional Owners:
                {formState?.owners?.map((value, i) => <span key={`${i + value}`}>{`${value} `}</span>)}
              </p>
              <p>
                Password:
                {((formState?.password) != (null)) && (
                  <>
                    {password}
                    {' '}
                    &nbsp;
                    <FontAwesomeIcon
                      style={{ cursor: 'pointer' }}
                      icon={faEye}
                      onClick={() => { setIsPasswordHidden(!isPasswordHidden) }}
                    />
                  </>
                )}
              </p>
              <Panel title="TERMS AND CONDITIONS" className="mt-5">
                <PanelBody>
                  In order to proceed with the database creation,
                  you must read and accept the terms of service
                  <div className="mt-2 is-flex">
                    <Form.Checkbox
                      name="checked"
                      id="checked"
                      checked={isChecked}
                      onChange={({ target }) => { setIsChecked(target.checked) }}
                    />
                    <Form.Label htmlFor="checked"><strong> &nbsp; I read and agree to the terms of service</strong></Form.Label>
                  </div>
                </PanelBody>
              </Panel>
              <ActionButtonWrapper>
                <ButtonGroup>
                  <Link to={ROUTES.listDatabases} replace>
                    <Button
                      label="Cancel"
                      icon={<Icon icon={<BiBlock />} />}
                    />
                  </Link>

                  <Link to={ROUTES.createDatabaseEdit} state={formState}>
                    <Button
                      label="Edit"
                      icon={<Icon icon={<BiPencil />} />}
                    />
                  </Link>
                </ButtonGroup>
                <Button
                  onClick={() => { setIsModalOpen(true) }}
                  isDisabled={!isChecked || !isValidState}
                  variant="primary"
                  label="Save"
                />

              </ActionButtonWrapper>
            </PanelBody>
          </Panel>
        </Container>
      </AppLayout>
    </>
  )
}

export default CreateDatabaseConfirm
