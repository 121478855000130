import React, { useEffect, useState } from 'react'
import BaseModal from './BaseModal'
import './Modal.scss'

function AlertModal({
  isOpen,
  okayButton,
  title,
  content: contentFromProps,
}: {
    title: string,
    isOpen: boolean,
    content?: React.ReactNode | string,
    okayButton: React.ReactNode
}) {
  const [content, setContent] = useState<React.ReactNode | string | undefined>()

  useEffect(() => {
    if (isOpen) {
      setContent(contentFromProps)
    }
  }, [isOpen, contentFromProps])

  return (
    <BaseModal
      isOpen={isOpen}
    >
      <h4 className="title is-4 mt-5">{title}</h4>
      {content != null && (
      <div className="subtitle is-6 mt-5 mb-5">
        {typeof content === 'string' ? <p>{content}</p> : (
          content
        )}
      </div>
      )}

      <div className="is-flex pt-5 is-flex-direction-row-reverse">
        {okayButton}
      </div>
    </BaseModal>
  )
}

AlertModal.defaultProps = {
  content: undefined,
}

export default AlertModal
