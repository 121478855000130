import { BiPlus, BiTrash } from 'react-icons/bi'
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react'
import styled from 'styled-components'
import { Button, Icon } from '@bespin-ui/react-ui-components'
import { Form, Table } from '../bulma'
import { useUpdateDatabaseOwners } from '../../hooks/api/databases'

type TOnChange = (owners: string[]) => void

const StyledTd = styled.td`
  position: relative;
`

const IconActionButton = styled.span`
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translate(-50%, -50%);
  button {
    height: 0;
    padding: 10px 5px;
  } 
`

function OwnersTable({
  onChange,
  initialValues = [],
  databaseId,
  onLoadingStatus,
  onError,
}: {
  databaseId?: string,
  onChange?: TOnChange,
  initialValues: string[],
  onLoadingStatus?: (isLoading: boolean) => void,
  onError?: (isError: boolean) => void
}) {
  const [owner, setOwner] = useState<string>('')
  const [owners, setOwners] = useState<string[]>(initialValues)
  const canMutate = databaseId != null
  const onLoadingStatusRef = useRef(onLoadingStatus)
  const onErrorRef = useRef(onError)
  const onChangeRef = useRef(onChange)

  const {
    mutate, isLoading, reset, isError,
  } = useUpdateDatabaseOwners()

  const addOwner = useCallback(() => {
    if (owner.length > 0) {
      setOwners([...owners, owner])
      setOwner('')
    }
  }, [owner, owners])

  const removeOwner = useCallback((
    rOwner: string,
  ) => {
    const tempOwners = owners.filter((currentOwner) => currentOwner !== rOwner)
    setOwners([...tempOwners])
  }, [owners])

  useEffect(() => {
    if (databaseId) { setOwners(initialValues) }
  }, [initialValues, databaseId])

  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])

  useEffect(() => {
    if (onChangeRef.current != null) {
      onChangeRef.current(owners)
    }
  }, [owners])

  useEffect(() => {
    onLoadingStatusRef.current = onLoadingStatus
  }, [onLoadingStatus])

  useEffect(() => {
    if (onLoadingStatusRef.current) { onLoadingStatusRef.current(isLoading) }
  }, [isLoading])

  useEffect(() => {
    onErrorRef.current = onError
  }, [onError])

  useEffect(() => {
    if (onErrorRef.current) {
      onErrorRef.current(isError)
    }
  }, [isError])

  const mutateOwners = (nOwner?: string) => {
    reset()
    const hasOwner = owners.some((v) => v === nOwner)
    if (databaseId) {
      if (hasOwner) {
        if (nOwner && nOwner.trim().length) {
          mutate({ databaseID: databaseId, owner: nOwner, remove: true })
        }
      } else if (owner && owner.trim().length) {
        mutate({ databaseID: databaseId, owner, remove: false })
        setOwner('')
      }
    }
  }

  return (
    <Table size="fullwidth" bordered hoverable>
      <thead>
        <tr>
          <th>Hub Username</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {
          owners.map((eachOwner, i) => (
            <tr key={`${i + eachOwner}`}>
              <td>{eachOwner}</td>
              <StyledTd>
                <IconActionButton>
                  <Button
                    icon={<Icon icon={<BiTrash />} />}
                    onClick={() => {
                      if (canMutate) {
                        mutateOwners(eachOwner)
                      } else {
                        removeOwner(eachOwner)
                      }
                    }}
                  />
                </IconActionButton>
              </StyledTd>
            </tr>
          ))
        }
        <tr>
          <td>
            <Form.Field>
              <Form.Control fullwidth={false}>
                <Form.Input
                  id="ownerInputField"
                  value={owner}
                  style={{ width: 'unset' }}
                  size="small"
                  name="owner"
                  onChange={(e) => setOwner(e.currentTarget.value)}
                />
              </Form.Control>
            </Form.Field>
          </td>
          <StyledTd>
            <IconActionButton id="addOwnerBtn">
              <Button
                onClick={() => {
                  if (canMutate) { mutateOwners() } else { addOwner() }
                }}
                icon={<Icon icon={<BiPlus />} />}
              />
            </IconActionButton>
          </StyledTd>
        </tr>
      </tbody>
    </Table>
  )
}

OwnersTable.defaultProps = {
  databaseId: undefined,
  onLoadingStatus: undefined,
  onError: undefined,
  onChange: undefined,
}

export default OwnersTable
