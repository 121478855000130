import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useParams, Link } from 'react-router-dom'
import { ROUTES, TITLES } from '../../constants'
import { DetailsTable, ErrorBannerNotification } from '../../components'
import { Columns, Container } from '../../components/bulma'
import { useFetchServerById } from '../../hooks/api/server'
import AppLayout from '../../layouts'

interface INavigationParams {
    serviceID: string
}

function ServerDetailsView() {
  const { serviceID } = useParams<keyof INavigationParams>()

  const { data: serverResponse, isError, isFetching: isLoading } = useFetchServerById(serviceID)
  const server = serverResponse?.data

  const data = useMemo(() => {
    const state = (() => {
      if (server?.state != null) {
        return server.state ? 'Active' : 'Inactive'
      }
      return ''
    })()
    const details = [
      { name: 'ID', value: server?._id ?? '' },
      { name: 'HOST', value: server?.host ?? '' },
      { name: 'Datacenter Name', value: server?.datacenterName ?? '' },
      { name: 'Provider Name', value: server?.providerName ?? '' },
      { name: 'Name', value: server?.name ?? '' },
      { name: 'Description', value: server?.description ?? '' },
      { name: 'State', value: state },
      { name: 'Disk Capacity (In GB)', value: (server?.capacity != null) ? server.capacity.toString() : '' },
      { name: 'Version', value: server?.databaseVersion ?? '' },
      { name: 'Custom Connection String', value: server?.customConnectionString ?? '' },
      { name: 'Custom Client Host', value: server?.customClientHost ?? '' },
      { name: 'Custom Configuration', value: '' },
      { name: 'Created By', value: server?.createdBy ?? '' },
      { name: 'Created At', value: server?.createdAt ?? '' },
      { name: 'Updated At', value: server?.updatedAt ?? '' },
    ]
    return details
  }, [server])

  return (
    <>
      <Helmet>
        <title>{TITLES.serverDetailsView}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <Container className="is-fluid">
          <Columns multiline={false} className="mt-5">
            <Columns.Column>
              <h4 className="title is-4">
                <Link to={ROUTES.listServers} replace>
                  <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
                </Link>
                <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                Servers &gt; View Server
                {' '}
                {server?.name != null ? `: ${server?.name}` : ''}
              </h4>
              {isError && (
                <ErrorBannerNotification
                  message="Failed to fetch server information. Please contact DBaaS support."
                />
              )}
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <DetailsTable data={data} />
            </Columns.Column>
          </Columns>
        </Container>
      </AppLayout>
    </>
  )
}

export default ServerDetailsView
