import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { Button } from '@bespin-ui/react-ui-components'
import {
  Button as BulmaButton, Form, Icon,
} from '../../components/bulma'
import {
  ConfirmModal, ErrorBannerNotification, Label, Panel, PanelBody,
} from '../../components'
import TextTooltip from '../../components/Tooltip'
import { useChangeLoadingStatus, useGetInitialServerData } from './EditServerByIdContext'
import { useUpdateServer } from '../../hooks/api/server'
import { ROUTES } from '../../constants'

const tooltipStrongTextStyle = { outline: '#000 thin solid', display: 'table-cell', backgroundColor: '#D3D3D3' }
const TooltipTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    div {
        p{
            line-height: 20px;
        }
        margin-bottom: 10px;
    }
`

const schema = yup.object({
  host: yup.string().required('is required').min(1),
  capacity: yup.number().positive().required('is required').min(1),
  state: yup.boolean().notRequired(),
  customConnectionString: yup.string(),
  user: yup.string().required('is required')
    .matches(/^[a-zA-Z][0-9a-zA-Z_]*$/, { message: 'Must begin with alpha and only contain alphanumeric characters. Underscore is also allowed' })
    .min(4, 'Must be longer than 3 chars.')
    .max(128, 'Must be shorter than 129 characters.'),
  password: yup.string()
    .required('is required')
    .notOneOf([yup.ref('user')], 'cannot be username')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@&^#$!`*';\-%])[A-Za-z\d\\@&^#$!`*';\-%]{10,128}$/,
      { message: "Password should meet specified requirements. It should satisfy all of these combinations: length 10-128, [a-z], [A-Z], numbers, @, &, ^, #, $, !, `, *, ', -, %,;, cannot be username" },
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Password must match'),
  customClientHost: yup.string(),
  pgPoolServers: yup.string(),
})

interface IEditableFields {
  host: string,
  capacity: number,
  state: boolean,
  customConnectionString: string,
  user:string,
  password: string,
  passwordConfirmation: string,
  customClientHost: string,
  pgPoolServers: string
}

function EditServerByIdForm() {
  const initialServerData = useGetInitialServerData()
  const isDisableEditableFields = useMemo(() => {
    if (initialServerData) {
      return false
    }
    return true
  }, [initialServerData])

  const {
    formState: { errors, isValid }, register, watch, clearErrors,
    handleSubmit,
    setError,
    reset,
  } = useForm<IEditableFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
  })
  const values = watch()

  useEffect(() => {
    if (initialServerData) {
      reset({
        capacity: Number(initialServerData.capacity),
        customClientHost: initialServerData.customClientHost,
        customConnectionString: initialServerData.customConnectionString,
        host: initialServerData.host,
        pgPoolServers: initialServerData.pgPoolServers,
        state: initialServerData.state,
      })
    }
  }, [initialServerData, reset])

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (!errors.password && values.password === values.passwordConfirmation) {
      clearErrors('passwordConfirmation')
    } else if (errors.password && values.passwordConfirmation.trim() !== '') {
      setError('passwordConfirmation', { type: 'validate', message: 'Password must match' })
    }
  }, [values.password, values.passwordConfirmation, clearErrors, setError, errors.password])

  const navigate = useNavigate()

  const {
    mutate, isError, isLoading, error,
    reset: resetServerError,
  } = useUpdateServer()

  const setLoadingStatus = useChangeLoadingStatus()

  useEffect(() => {
    if (initialServerData) { setLoadingStatus(isLoading) }
  }, [isLoading, initialServerData, setLoadingStatus])

  const onSubmit = () => {
    if (initialServerData && !isLoading) {
      if (isError) {
        resetServerError()
      }
      mutate({
        ...initialServerData,
        ...values,
      }, {
        onSuccess: () => {
          bulmaToast.toast({
            message: `<b>${initialServerData?.name}</b> Server is successfully updated`,
            type: 'is-success',
            dismissible: true,
            duration: 6000,
            position: 'bottom-right',
            animate: { in: 'fadeIn', out: 'fadeOut' },
          })
          navigate(ROUTES.listServers, {
            replace: true,
          })
        },
      })
    }
  }

  return (
    <>
      <ConfirmModal
        isReverseActionButtonsPosition
        isOpen={isModalOpen}
        title={`Are you sure you want to update ${initialServerData?.name} ?`}
        description={`You are about to update the server ${initialServerData?.name}, you can not undo this action, are you sure you want to continue?`}
        okayButton={(
          <BulmaButton disabled={isLoading} onClick={onSubmit} color="success">
            Update Server
          </BulmaButton>
        )}
        cancelButton={
          <BulmaButton data-testid="modalCancelButton" disabled={isLoading} color="default" onClick={() => { setIsModalOpen(false) }}>Cancel</BulmaButton>
        }
      />
      {isError && (
      <ErrorBannerNotification message={error?.message ?? ''} dismissButtonCallback={resetServerError} />
      )}
      <Panel title="Edit server">
        <PanelBody>
          <form>
            <Form.Field>
              <Label
                label="Host"
                for="host"
                required
                popover={(
                  <TextTooltip>
                    <TooltipTextContainer>
                      <p><b>This field value should be in following format:</b></p>
                      <div>
                        <p>If provider is MYSQL: [hostname]</p>
                        <p>Example: </p>
                        <p style={tooltipStrongTextStyle}>
                          dbaasmydbdev01.starwave.com
                        </p>
                      </div>
                      <div>
                        <p>If provider is MSSQL: </p>
                        <p> [hostname]:[portnumber];databaseName=dbaas</p>
                        <p> Example:</p>
                        <p style={tooltipStrongTextStyle}>
                          TDSSQLAGL15.mgmt.prod:1433;databaseName=dbaas
                        </p>
                      </div>
                      <div>
                        <p> If provider is PostgreSQL: </p>
                        <p>[hostname]:[portnumber]/postgres</p>
                        <p>Example:</p>
                        <p style={tooltipStrongTextStyle}>
                          qn7dsc01pgdb01.starwave.com:5432/postgres
                        </p>
                      </div>
                    </TooltipTextContainer>
                  </TextTooltip>
              )}
              />
              <Form.Control>
                <input
                  disabled={isDisableEditableFields}
                  className={classNames('input', { 'is-danger': errors.host != null })}
                  {...register('host')}
                  id="host"
                  aria-label="host"
                />
              </Form.Control>
              <Form.Help color="danger">
                {errors.host != null ? errors.host.message : ''}
              </Form.Help>
            </Form.Field>
            <Form.Field>
              <Label label="Provider Name" for="providerName" required />
              <Form.Control>
                <div className="select is-fullwidth">
                  <select disabled value={initialServerData?.providerName ?? ''} aria-label="providerName">
                    {!initialServerData?.providerName && <option value="">{' '}</option> }
                    <option value="MSSQL">MSSQL</option>
                    <option value="MYSQL">MYSQL</option>
                    <option value="PostgreSQL">PostgreSQL</option>
                  </select>
                </div>
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Label label="Datacenter Name" for="datacenterName" required />
              <Form.Control>
                <input
                  disabled
                  className="input"
                  value={initialServerData?.datacenterName ?? ''}
                  id="datacenterName"
                  aria-label="datacenterName"
                />
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Label label="Name" for="name" required />
              <Form.Control>
                <input
                  disabled
                  className="input"
                  value={initialServerData?.name ?? ''}
                  id="name"
                  aria-label="name"
                />
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Label label="Description" for="description" required />
              <Form.Control>
                <input
                  disabled
                  className="input"
                  value={initialServerData?.description ?? ''}
                  id="description"
                  aria-label="description"
                />
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Label label="Disk Capacity (In GB)" for="capacity" required />
              <Form.Control>
                <input
                  disabled={isDisableEditableFields}
                  className={classNames('input', { 'is-danger': errors.capacity != null })}
                  {...register('capacity', { valueAsNumber: true })}
                  type="number"
                  data-testid="capacity"
                  aria-label="capacity"
                />
              </Form.Control>
              <Form.Help color="danger">
                {errors.capacity != null ? errors.capacity.message : ''}
              </Form.Help>
            </Form.Field>

            <Form.Field>
              <Label label="Database Version" for="databaseVersion" required />
              <Form.Control>
                <input
                  disabled
                  className="input"
                  value={initialServerData?.databaseVersion ?? ''}
                  id="databaseVersion"
                  aria-label="databaseVersion"
                />
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Label label="Custom Connection String" for="customConnectionString" />
              <Form.Control>
                <input
                  disabled={isDisableEditableFields}
                  className="input"
                  {...register('customConnectionString')}
                  id="customConnectionString"
                  aria-label="customConnectionString"
                />
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Label label="Custom Client Host" for="customClientHost" />
              <Form.Control>
                <input
                  disabled={isDisableEditableFields}
                  className="input"
                  {...register('customClientHost')}
                  id="customClientHost"
                  aria-label="customClientHost"
                />
              </Form.Control>
            </Form.Field>

            <Form.Field>
              <Label label="Custom Server Config (only used for PGSQL)" for="pgPoolServers" />
              <Form.Control>
                <input
                  disabled={isDisableEditableFields}
                  className="input"
                  {...register('pgPoolServers')}
                  id="pgPoolServers"
                  aria-label="pgPoolServers"
                />
              </Form.Control>
            </Form.Field>

            <Form.Field horizontal className="is-align-content-center mb-0">
              <Form.Control>
                <label className="checkbox mt-5" htmlFor="state">
                  <span className="title is-6">State &nbsp;</span>
                  <input
                    type="checkbox"
                    id="state"
                    {...register('state')}
                    disabled={isDisableEditableFields}
                    aria-label="state"
                  />
                  &nbsp; Active
                </label>
              </Form.Control>
            </Form.Field>

            <Panel className="mt-5" title="Change Username and Password (must be done at the same time)">
              <PanelBody>
                <Form.Field>
                  <Label label="Admin Username" required />
                  <Form.Control>
                    <input
                      className={classNames('input', { 'is-danger': errors.user != null })}
                      {...register('user')}
                      id="user"
                      aria-label="user"
                    />
                  </Form.Control>
                  <Form.Help color="danger">
                    {errors.user != null ? errors.user.message : ''}
                  </Form.Help>
                </Form.Field>

                <Form.Field>
                  <Label label="Password" required />
                  <Form.Control>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      className={classNames('input', { 'is-danger': errors.password != null })}
                      {...register('password')}
                      id="password"
                      aria-label="password"
                      data-testid="password"
                    />
                    <Icon align="right">
                      <FontAwesomeIcon
                        icon={faEye}
                        color="rgb(74, 74, 74)"
                        cursor="pointer"
                        pointerEvents="initial"
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    </Icon>
                  </Form.Control>
                  <Form.Help color="danger">
                    {errors.password != null ? errors.password.message : ''}
                  </Form.Help>
                </Form.Field>

                <Form.Field>
                  <Label label="Password Confirmation" required />
                  <Form.Control>
                    <input
                      type={showConfirmPassword ? 'text' : 'password'}
                      className={classNames('input', { 'is-danger': errors.passwordConfirmation != null })}
                      {...register('passwordConfirmation')}
                      id="passwordConfirmation"
                      aria-label="passwordConfirmation"
                      data-testid="passwordConfirmation"
                    />
                    <Icon align="right">
                      <FontAwesomeIcon
                        icon={faEye}
                        color="rgb(74, 74, 74)"
                        cursor="pointer"
                        pointerEvents="initial"
                        onClick={() => setShowConfirmPassword(!showPassword)}
                      />
                    </Icon>
                  </Form.Control>
                  <Form.Help color="danger">
                    {errors.passwordConfirmation != null ? errors.passwordConfirmation.message : ''}
                  </Form.Help>
                </Form.Field>
              </PanelBody>
            </Panel>
            <Form.Field className="is-flex is-justify-content-space-between mt-5">
              <Button
                isDisabled={isLoading}
                onClick={() => { navigate(-1) }}
                variant="secondary"
                label="Cancel"
              />
              <Button
                label="Submit"
                variant="primary"
                isDisabled={!isValid || isLoading}
                onClick={handleSubmit(() => setIsModalOpen(true))}
              />
            </Form.Field>
          </form>
        </PanelBody>
      </Panel>
    </>
  )
}

EditServerByIdForm.defaultProps = {
  initialServerData: undefined,
}

export default EditServerByIdForm
