import React, { useMemo, useState } from 'react'
import * as bulmaToast from 'bulma-toast'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { BiEnvelope, BiPlus } from 'react-icons/bi'
import { Button, Icon } from '@bespin-ui/react-ui-components'
import { ColumnDef } from '@tanstack/react-table'
import {
  ConfirmModal, ErrorBannerNotification, Table, ActionButtons,
} from '../../components'
import AppLayout from '../../layouts/AppLayout'
import ROUTES from '../../constants/ROUTES'
import useFetchDatabases from '../../hooks/api/databases/useFetchDatabases'
import { useDeleteDatabaseById } from '../../hooks/api/databases'
import IDatabase from '../../types/databases'
import { TITLES } from '../../constants'
import { Button as BulmaButton, Columns } from '../../components/bulma'

import MongoData from './mockedMongo'
import useEnvironment from '../../hooks/env'

const IconSpace = styled.span`
    padding-right: 10px;
`
const RightIcons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
`

function ListDBaaS() {
  const navigation = useNavigate()
  const { pathname } = useLocation()

  const { data, isFetching: isDatabaseLoading } = useFetchDatabases()

  const [selectedDatabase, setSelectedDatabase] = useState<IDatabase | undefined>()

  const {
    mutate, isLoading: isDatabaseDeleteLoading, isError, reset, error,
  } = useDeleteDatabaseById()

  const isLoading = isDatabaseLoading || isDatabaseDeleteLoading
  const env = useEnvironment()

  const tableRowData = useMemo(() => {
    const isMockMongoDbUi = env === 'dev' // Delete once done with Mock

    if (isMockMongoDbUi) { // Delete once done with Mock
      let rowData: IDatabase[] = data?.data ?? []
      const temp = ['In Progress', 'Complete', 'Error']
      // Grabbing the first item
      const mongoTemp = rowData.length ? MongoData.map(
        (md) => ({ ...md, _id: rowData[0]._id }), // Grabbing the first
      ) : []
      rowData = [...rowData, ...mongoTemp].sort(
        (a, b): number => (new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
      )
      rowData = rowData
        .map((eachData) => ({ ...eachData, status: temp[Math.floor(Math.random() * temp.length)] }))

      return rowData
    }

    return data?.data ?? []
  }, [data?.data, env])

  const columns: ColumnDef<IDatabase>[] = useMemo(() => [
    {
      header: 'Created By',
      accessorKey: 'createdBy',
      id: 'createdBy',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Instance Name',
      accessorKey: 'databaseName',
      id: 'databaseName',
    },
    {
      header: 'Provider',
      accessorKey: 'providerName',
      id: 'providerName',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Admin User Name',
      accessorKey: 'adminUsername',
      id: 'adminUsername',
    },
    {
      header: 'Data Center',
      accessorKey: 'datacenterName',
      id: 'datacenterName',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'State',
      accessorKey: 'status',
      id: 'status',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Status',
      cell: ({ row }) => (row.original.state ? 'Active' : 'Inactive'),
      accessorKey: 'state',
      id: 'state',
      meta: {
        width: '100px',
      },
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => ActionButtons(
        {
          ...{
            viewCallback: () => { navigation(`${pathname}/${row.original._id}/view`) },
            editCallback: () => { navigation(`${pathname}/${row.original._id}/edit`) },
            deleteCallback: () => { setSelectedDatabase(row.original) },
          },
        },
      ),
      meta: {
        width: '190px',
      },
    },
  ], [navigation, pathname])

  return (
    <>
      <Helmet>
        <title>{TITLES.listDBaaS}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <ConfirmModal
          title={`Are you sure you want to delete ${selectedDatabase?.databaseName}`}
          description={`You are about to delete the database ${selectedDatabase?.databaseName}, you can not undo this action, are you sure you want to continue?`}
          isOpen={selectedDatabase != null}
          okayButton={(
            <BulmaButton
              color="success"
              onClick={() => {
                if (selectedDatabase != null) {
                  mutate(selectedDatabase, {
                    onSettled: () => { setSelectedDatabase(undefined) },
                    onSuccess: () => {
                      bulmaToast.toast({
                        message: `<b>${selectedDatabase?.databaseName}</b> Database is successfully deleted`,
                        type: 'is-success',
                        dismissible: true,
                        duration: 6000,
                        position: 'bottom-right',
                        animate: { in: 'fadeIn', out: 'fadeOut' },
                      })
                    },
                  })
                } else {
                  setSelectedDatabase(undefined)
                }
              }}
              disabled={isDatabaseDeleteLoading || selectedDatabase?.providerName.toLowerCase() === 'mongodb'}
            >
              Delete
            </BulmaButton>
          )}
          cancelButton={(
            <BulmaButton
              disabled={isDatabaseDeleteLoading}
              onClick={() => { setSelectedDatabase(undefined) }}
            >
              Cancel
            </BulmaButton>
          )}
        />
        <Columns>
          <Columns.Column
            desktop={{
              size: 6,
            }}
            mobile={{
              size: 12,
            }}
          >
            <h1 className="title is-4">
              <IconSpace>
                <FontAwesomeIcon icon={faDatabase} />
              </IconSpace>
              On Demand Databases & Clusters
            </h1>
          </Columns.Column>

          <Columns.Column
            desktop={{
              textAlign: 'right',
            }}
            mobile={{
              size: 12,
              textAlign: 'justify',
            }}
          >
            <RightIcons>
              <Button
                onClick={() => { window.location.href = 'mailto:DTSS.DBAAS.Program.Team@disney.com' }}
                size="small"
                variant="secondary"
                label="Feedback"
                icon={<Icon icon={<BiEnvelope />} />}
              />
              <Button
                onClick={() => { navigation(ROUTES.createDatabase) }}
                size="small"
                variant="primary"
                label="Create Database"
                icon={<Icon icon={<BiPlus />} />}
              />
            </RightIcons>
          </Columns.Column>
          {isError && (
            <Columns.Column size={12}>
              <ErrorBannerNotification
                message={error?.message ?? ''}
                dismissButtonCallback={reset}
              />
            </Columns.Column>
          )}
        </Columns>
        <Table columns={columns} data={tableRowData} />
      </AppLayout>
    </>
  )
}
export default ListDBaaS
