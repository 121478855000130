import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { BiPlus } from 'react-icons/bi'
import { Button, Icon } from '@bespin-ui/react-ui-components'
import { ColumnDef } from '@tanstack/table-core'
import {
  ActionButtons, ConfirmModal, ErrorBannerNotification, Table,
} from '../../components'
import AppLayout from '../../layouts'
import { ROUTES, TITLES } from '../../constants'
import IServer from '../../types/IServer'
import { useDeleteServerById, useFetchServers } from '../../hooks/api/server'
import { Button as BulmaButton, Columns, Container } from '../../components/bulma'

function ListServer() {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  const [selectedServer, setSelectedServer] = useState<IServer | undefined>()

  const { data, isFetching: isServerLoading } = useFetchServers()

  const {
    mutate, isLoading: isServerDeleteLoading, isError, reset, error,
  } = useDeleteServerById()

  const isLoading = isServerLoading || isServerDeleteLoading

  const columns: ColumnDef<IServer>[] = React.useMemo(() => [
    {
      header: 'Host',
      accessorKey: 'host',
      id: 'host',
      meta: {
        width: '20%',
      },
    },
    {
      header: 'Provider Name',
      accessorKey: 'providerName',
      id: 'providerName',
      meta: {
        width: '20%',
      },
    },
    {
      header: 'Name',
      accessorKey: 'name',
      id: 'name',
      meta: {
        width: '20%',
      },
    },
    {
      header: 'Description',
      accessorKey: 'description',
      id: 'description',
      meta: {
        width: '20%',
      },
    },
    {
      header: 'State',
      accessorKey: 'state',
      id: 'state',
      cell: ({ row }) => (row.original.state ? 'Active' : 'Inactive'),
      meta: {
        width: '10%',
      },
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => ActionButtons(
        {
          ...{
            viewCallback: () => {
              navigate(`${pathname}/${row.original._id}/view`)
            },
            editCallback: () => { navigate(`${pathname}/${row.original._id}/edit`) },
            deleteCallback: () => { setSelectedServer(row.original) },
          },
        },
      ),
      meta: {
        width: '190px',
      },
    },
  ], [navigate, pathname])

  const onClickCreateServerPage = () => {
    navigate(ROUTES.createServerConfigure)
  }

  return (
    <>
      <Helmet>
        <title>{TITLES.listServer}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <ConfirmModal
          title={`Are you sure you want to delete ${selectedServer?.name}`}
          description={`You are about to delete the server ${selectedServer?.name}, you can not undo this action, are you sure you want to continue?`}
          isOpen={selectedServer != null}
          okayButton={(
            <BulmaButton
              color="success"
              onClick={() => {
                if (selectedServer != null) {
                  mutate(selectedServer, {
                    onSettled: () => { setSelectedServer(undefined) },
                    onSuccess: () => {
                      bulmaToast.toast({
                        message: `<b>${selectedServer.name}</b> Server Configuration is successfully deleted`,
                        type: 'is-success',
                        dismissible: true,
                        duration: 6000,
                        position: 'bottom-right',
                        animate: { in: 'fadeIn', out: 'fadeOut' },
                      })
                    },
                  })
                } else {
                  setSelectedServer(undefined)
                }
              }}
              disabled={isServerDeleteLoading}
            >
              Delete
            </BulmaButton>
          )}
          cancelButton={(
            <BulmaButton
              disabled={isServerDeleteLoading}
              onClick={() => { setSelectedServer(undefined) }}
            >
              Cancel
            </BulmaButton>
          )}
        />
        <Container className="is-fluid pr-0 pl-0">
          <Columns multiline={false}>
            <Columns.Column>
              <h4 className="title is-4">
                <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                Database Servers

              </h4>
            </Columns.Column>
          </Columns>
          <Columns multiline={false}>
            <Columns.Column className="is-flex is-flex-direction-row-reverse">
              <Button
                onClick={onClickCreateServerPage}
                size="small"
                variant="primary"
                label="Create Server Configuration"
                icon={<Icon icon={<BiPlus />} />}
              />
            </Columns.Column>
          </Columns>
          {isError && (
            <Columns>
              <Columns.Column size={12}>
                <ErrorBannerNotification
                  message={error?.message ?? ''}
                  dismissButtonCallback={reset}
                />
              </Columns.Column>
            </Columns>
          )}
          <Columns>
            <Columns.Column>
              <Table
                columns={columns}
                data={data?.data ?? []}
              />
            </Columns.Column>
          </Columns>
        </Container>
      </AppLayout>
    </>
  )
}

export default ListServer
