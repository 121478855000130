import React, { ComponentType } from 'react'
import Modal from 'react-modal'
import './Modal.scss'

type Props = ReactModal['props']

const ReactModal = Modal as ComponentType<ReactModal['props']>

function BaseModal(props: Props) {
  return (
    <ReactModal
      closeTimeoutMS={300}
      ariaHideApp={false}
      style={{
        content: {
          top: ' 5%',
          position: 'relative',
          backgroundColor: '#fefefe',
          margin: 'auto',
          padding: '1rem',
          maxWidth: '600px',
          outline: 'none',
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(66, 66, 66, .5)',
        },
      }}
      {...props}
    />
  )
}

export default BaseModal
