import { useQuery } from 'react-query'
import { useDBaaSClient } from '../../client'
import { useFetchIsAdmin } from '../admin'

interface IDatabase {
  _id: string,
  serverId: string,
  databaseName: string,
  adminUsername: string,
  providerName: string,
  taxonomyId: string,
  connectionString: string,
  state: boolean,
  size: number | string,
  updatedAt: string,
  createdAt: string,
  createdBy: string,
  updatedBy: string,
  owners: string[],
  datacenterName: string
}

const useFetchDatabaseById = (databaseID?: string) => {
  const { get } = useDBaaSClient()
  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  const canFetch = !isLoading && isSuccess

  const query = useQuery(['databases', databaseID], () => get<IDatabase>(`/databases/${databaseID}`), {
    enabled: canFetch && databaseID != null,
    retry: false,
  })

  const isFetching = isLoading || query.isFetching

  return { ...query, isFetching }
}

export default useFetchDatabaseById
