import React from 'react'
import { Icon, Navbar } from '@bespin-ui/react-ui-components'
import { useAuth } from '../../providers/AuthProvider'

function TopNav() {
  const { isAdmin } = useAuth()

  return (
    <Navbar>
      <Navbar.Brand>
        <Navbar.Item>
          <Icon icon="BiData" contrast variant="secondary" />
          <Navbar.Link href="/">{`DBaaS ${isAdmin ? 'Admin' : ''}`}</Navbar.Link>
        </Navbar.Item>
        <Navbar.Burger />
      </Navbar.Brand>
      <Navbar.Menu>
        <Navbar.Link href="/dbaasAdmin">Home</Navbar.Link>
        <Navbar.Link href="https://bespin.disney.com">Bespin Portal</Navbar.Link>
      </Navbar.Menu>
    </Navbar>
  )
}

export default TopNav
